import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CorePublicModule } from './core/core-public.module';
import { JwtInterceptor } from './_interceptors';
import { HomeContainerComponent } from './main/app/components/home-container/home-container.component';
import { JwtModule } from '@auth0/angular-jwt';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserHubComponent } from './main/app/components/user-hub/user-hub.component';
import { SharedCommonModule } from './shared/common/shared-common.module';
import { DiscussionsModule } from "./shared/discussions/discussions.module";
import { CookieService } from 'ngx-cookie-service';
import { SocketIoModule } from 'ngx-socket-io';
import { socketIoConfig } from './_config/socket-io.config';
import { MainLandingComponent } from './main/app/components/main-landing/main-landing.component';
import { SearchEverythingComponent } from './main/app/components/search-everything/search-everything.component';
import { SearchEverythingContainerComponent } from './main/app/components/search-everything-container/search-everything-container.component';
import { FiltersModule } from './shared/filters/filters.module';
import { FormsModule } from '@angular/forms';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { CustomRouteReuseStrategy } from './_strategies/route-reuse.strategy';
import { ScrollService } from './_services/scroll.service';
import { TutorialsModule } from "./shared/tutorials/tutorials.module";
import { ChunkErrorHandlerService } from './_services/chunk-error-handler.service';
import { LineBreaksPipe } from './_pipes';
import { TermsOfServiceComponent } from './main/app/components/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './main/app/components/privacy-policy/privacy-policy.component';
import { DeleteAccountComponent } from './main/user/components/delete-account/delete-account.component';
import { AuthenticationModule } from './shared/authentication/authentication.module';

export function tokenGetter() {
    let user: any = localStorage.getItem('hubHiveUser');
    return user ? user['token'] : null;
}
@NgModule({
    declarations: [
        AppComponent,
        HomeContainerComponent,
        UserHubComponent,
        MainLandingComponent,
        SearchEverythingComponent,
        SearchEverythingContainerComponent,
        TermsOfServiceComponent,
        PrivacyPolicyComponent,
        DeleteAccountComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        RouterModule,
        AppRoutingModule,
        CorePublicModule,
        SharedCommonModule,
        TutorialsModule,
        FiltersModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: ['localhost:3000', 'dev.hubhiveconnect.com', 'prerelease.hubhive.com', 'hubhive.com']
            }
        }),
        GoogleTagManagerModule.forRoot({
            id: 'GTM-569MDG3L'
        }),
        BrowserAnimationsModule,
        DiscussionsModule,
        SocketIoModule.forRoot(socketIoConfig),
        FormsModule,
        ZXingScannerModule,
        TutorialsModule,
        AuthenticationModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        // { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
        provideAnimationsAsync(),
        provideHttpClient(withInterceptorsFromDi()),
        CookieService,
        ScrollService,
        ChunkErrorHandlerService,
    ],
})
export class AppModule {
}
