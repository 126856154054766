import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationCodeComponent } from './components/confirmation-code/confirmation-code.component';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
    declarations: [
        ConfirmationCodeComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
    ],
    exports: [
        ConfirmationCodeComponent
    ]
})
export class AuthenticationModule { }
