import { Component } from '@angular/core';

@Component({
    selector: 'download-app-ribbon',
    templateUrl: './download-app-ribbon.component.html',
    styleUrl: './download-app-ribbon.component.scss'
})
export class DownloadAppRibbonComponent {
    hideDownloadAppRibbon: boolean = false
    appleStoreUrl = 'https://apps.apple.com/us/app/hubhive/6593692270';
    googlePlayStoreUrl = 'https://play.google.com/store/apps/details?id=com.hubhive.mobile';

    constructor() {
        if (!/android/i.test(navigator.userAgent) && !/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            this.hideDownloadAppRibbon = true
        }


        setTimeout(() => {
            const ribbon = document.querySelector('.download-ribbon');
            ribbon?.classList.add('visible');
        }, 1000);

    }
    
    redirectToApp(): void {

        const appStoreUrl = /android/i.test(navigator.userAgent)
            ? this.googlePlayStoreUrl
            : this.appleStoreUrl;

        window.location.href = appStoreUrl;

    }

    closeRibbon() {
        const ribbon = document.querySelector('.download-ribbon');
        ribbon?.classList.add('closed');
    }


}
