import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})

export class InviteRedirectResolver implements Resolve<void> {
    constructor(private router: Router) { }

    appleStoreUrl = 'https://apps.apple.com/us/app/hubhive/6593692270';
    googlePlayStoreUrl = 'https://play.google.com/store/apps/details?id=com.hubhive.mobile';

    resolve(): void {
        const appStoreUrl = /android/i.test(navigator.userAgent)
            ? this.googlePlayStoreUrl
            : this.appleStoreUrl;

        const redirectUrl = window.location.href.replace("/invite", "")

        if (this.isMobileDevice()) {
            window.location.href = appStoreUrl
            return;
        }

        window.location.href = redirectUrl
        return
    }

    private isMobileDevice(): boolean {
        return /Mobi|Android/i.test(navigator.userAgent);
    }
}