import { Injectable } from '@angular/core'
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http'

import { Business, Config, ConfirmationCode, Hive, User, UserAuth, UserEntity, UserHub, WaitList } from '../_models'
import { Observable, map } from 'rxjs'
import { ConfirmationCodeType } from '../_enums'

@Injectable({ providedIn: 'root' })
export class UserAPIService {
    constructor(private http: HttpClient) { }

    getUser(id: string) {
        return this.http.get<User>(`${Config.apiUrl}/user/${id}`)
    }

    addUser(user: {}, type: string) {
        return this.http.post<User>(`${Config.apiUrl}/user`, { ...user, type: type })
    }

    addToWaitlist(user: {}) {
        return this.http.post<WaitList>(`${Config.apiUrl}/user/add-to-waitlist`, { ...user })
    }

    updateUser(user: User) {
        return this.http.patch<User>(`${Config.apiUrl}/user`, user)
    }

    deleteUser(code: string, phone: string) {
        return this.http.delete(`${Config.apiUrl}/user`, { body: { code, phone }, responseType: 'text' });
    }

    list(limit: number = 0) {
        return this.http.get<User[]>(`${Config.apiUrl}/user`, { params: { limit: limit } })
    }

    getHiveUsers(handle: string, limit: number = 0) {
        return this.http.get<User[]>(`${Config.apiUrl}/user/hive/${handle}`, { params: { limit: limit } })
    }

    getInvitedHiveUsers(handle: string, limit: number = 0) {
        return this.http.get<User[]>(`${Config.apiUrl}/user/hive-invitees/${handle}`, { params: { limit: limit } })
    }

    getCurrentUserHiveContacts(searchTerm: string = "", type: string = ""): Observable<User[]> {
        return this.http.get<User[]>(`${Config.apiUrl}/user/hive-contacts`, { params: { search: searchTerm, type: type } })
    }

    GetHiveContactsNotAttendingEvent(eventId: string): Observable<User[]> {
        return this.http.get<User[]>(`${Config.apiUrl}/user/hive/event/not-attending`, { params: { event_id: eventId } })
    }

    uploadAvatar(imageName: string, base64Image: string): Observable<User> {
        return this.http.post<User>(`${Config.apiUrl}/user/avatar`, { avatar: base64Image, file_name: imageName })
    }

    getUserHub(): Observable<UserHub> {
        return this.http.get<UserHub>(`${Config.apiUrl}/user/hub`)
    }

    getUserBusinesses(): Observable<Business[]> {
        return this.http.get<Business[]>(`${Config.apiUrl}/user/businesses`)
    }

    getUserEntities(): Observable<UserEntity[]> {
        return this.http.get<UserEntity[]>(`${Config.apiUrl}/user/entities`)
    }

    getUserSuggestions(query: string, postId: string = ''): Observable<User[]> {
        return this.http.get<User[]>(`${Config.apiUrl}/user/suggestions`, { params: { query: query, post_id: postId } })
    }

    sendConfirmationCode(phone: string, type: ConfirmationCodeType, referral: string = ''): Observable<ConfirmationCode | null> {
        return this.http.post<ConfirmationCode | null>(`${Config.apiUrl}/user/confirmation-code`, { phone_number: phone, type: type, referral: referral })
    }

    confirmPhone(code: string, phone: string): Observable<UserAuth> {
        return this.http.post<UserAuth>(`${Config.apiUrl}/user/confirm-phone`, { code: code, phone: phone })
    }
}