import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmationCodeType } from 'src/app/_enums';
import { User, UserAuth } from 'src/app/_models';
import { UserAPIService } from 'src/app/_api-services';
import { AuthenticationService, HeaderService, NavigationService } from 'src/app/_services';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'delete-account',
    templateUrl: './delete-account.component.html',
    styleUrl: './delete-account.component.scss'
})
export class DeleteAccountComponent {

    private subscriptions: Subscription = new Subscription()

    template: string = 'delete-account'
    confirmationCodeType: ConfirmationCodeType = ConfirmationCodeType.Security
    phoneNumber: string = ''
    currentUser: UserAuth
    isLoading = false
    isValidCode = true

    constructor(
        private navigationService: NavigationService,
        private headerService: HeaderService,
        private router: Router,
        private userAPIService: UserAPIService,
        private authService: AuthenticationService,
    ) {
        this.currentUser = this.authService.currentUserValue
        this.phoneNumber = this.currentUser.user.phone
    }

    goBack() {
        this.navigationService.goBack()
    }

    deleteAccountPressed() {
        if (this.currentUser.user.id == '') {
            this.router.navigate(['/login'], { queryParamsHandling: 'preserve' })
            return
        }

        this.isLoading = true

        this.subscriptions.add(
            this.userAPIService.sendConfirmationCode(this.phoneNumber, this.confirmationCodeType).subscribe({
                next: () => {
                    this.isLoading = false;
                    this.template = 'confirm-delete'
                },
                error: err => {
                    this.router.navigate(['/login'], { queryParamsHandling: 'preserve' })
                }
            })
        )

    }

    deleteAccount(data: { phone: string, code: string }): void {
        this.isLoading = true
        this.userAPIService.deleteUser(data.code, data.phone).subscribe({
            next: (response) => {
                this.isLoading = false
                this.router.navigate(['/login'], { queryParamsHandling: 'preserve' })
            },
            error: (err: HttpErrorResponse) => {
                this.isLoading = false
                this.isValidCode = false
            }
        })
    }


}
