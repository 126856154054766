import { Injectable, OnDestroy } from '@angular/core'
import { Router, ActivatedRouteSnapshot, NavigationEnd, ActivatedRoute } from '@angular/router'
import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class RouteService implements OnDestroy {
    private subscriptions: Subscription = new Subscription()
    private currentRouteData = new BehaviorSubject<any>(null)
    private currentRouteParams = new BehaviorSubject<any>(null)
    private currentRoute = new BehaviorSubject<string | null>(null)

    constructor(private router: Router, private route: ActivatedRoute) {
        this.subscriptions.add(
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd)
            ).subscribe(() => {
                const root = this.route.root
                this.updateRouteDataAndParams(root)
                this.currentRoute.next(this.router.url)
            })    
        )
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    private updateRouteDataAndParams(route: ActivatedRoute | null) {
        let data = {}
        let params = {}
        
        while (route) {
            if (Object.keys(route.data).length > 0) {
                data = { ...data, ...route.snapshot.data }
            }
            
            if (Object.keys(route.params).length > 0) {
                params = { ...params, ...route.snapshot.params }
            }
            
            route = route.firstChild
        }
    
        this.currentRouteData.next(data)
        this.currentRouteParams.next(params)
    }
    
    getCurrentRoute(): Observable<string|null> {
        return this.currentRoute.asObservable();
    }

    getRouteData(): Observable<any> {
        return this.currentRouteData.asObservable()
    }

    getRouteParams(): Observable<any> {
        return this.currentRouteParams.asObservable()
    }
}
