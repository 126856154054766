import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { HiveHubComponent } from './shared/common/components/hive-hub/hive-hub.component';
import { LoginComponent } from './main/registration/components/login/login.component';
import { NotFoundComponent } from './shared/common/components/not-found/not-found.component';
import { HomeContainerComponent } from './main/app/components/home-container/home-container.component';
import { RequestPasswordResetComponent } from './main/registration/components/request-password-reset/request-password-reset.component';
import { ResetPasswordComponent } from './main/registration/components/reset-password/reset-password.component';
import { ConfirmAccountComponent } from './main/user/components/confirm-account/confirm-account.component';
import { PeopleHubComponent } from './main/user/components/people-hub/people-hub.component';
import { ConfirmBusinessComponent } from './main/user/components/confirm-business/confirm-business.component';
import { WaitlistComponent } from './main/registration/components/waitlist/waitlist.component';
import { TermsOfServiceComponent } from './main/app/components/terms-of-service/terms-of-service.component';
import { RegistrationGuard } from './_guard';
import { PrivacyPolicyComponent } from './main/app/components/privacy-policy/privacy-policy.component';
import { DeleteAccountComponent } from './main/user/components/delete-account/delete-account.component';
import { InviteRedirectResolver } from './_resolver/invite-redirect.resolver';



const routes: Routes = [
    {
        path: '',
        component: HomeContainerComponent,
        data: { reuse: true },
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'forgot-password',
        component: RequestPasswordResetComponent
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent
    },
    {
        path: 'terms-of-service',
        component: TermsOfServiceComponent
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
    },
    {
        path: 'confirm-account',
        component: ConfirmAccountComponent
    },
    {
        path: 'confirm-business',
        component: ConfirmBusinessComponent
    },
    {
        path: 'delete-account',
        component: DeleteAccountComponent,
    },
    {
        path: 'register',
        loadChildren: () =>
            import('./main/registration/registration.module')
                .then(m => m.RegistrationModule)
                .catch(err => {
                    console.log('Error loading module, retrying...', err)
                    window.location.reload()
                    return import('./main/registration/registration.module').then(m => m.RegistrationModule)
                })
    },
    {
        path: 'waitlist',
        component: WaitlistComponent
    },
    {
        path: 'hives',
        component: HiveHubComponent,
        data: { reuse: true },
        canActivate: [RegistrationGuard]
    },
    {
        path: 'business',
        loadChildren: () =>
            import('./main/business/business.module')
                .then(m => m.BusinessModule)
                .catch(err => {
                    console.log('Error loading module, retrying...', err)
                    window.location.reload()
                    return import('./main/business/business.module').then(m => m.BusinessModule)
                }),
        canActivate: [RegistrationGuard]
    },
    {
        path: 'event',
        loadChildren: () =>
            import('./main/event/event.module')
                .then(m => m.EventModule)
                .catch(err => {
                    console.log('Error loading module, retrying...', err)
                    window.location.reload()
                    return import('./main/event/event.module').then(m => m.EventModule)
                }),
        canActivate: [RegistrationGuard]
    },
    {
        path: 'user',
        loadChildren: () =>
            import('./main/user/user.module')
                .then(m => m.UserModule)
                .catch(err => {
                    console.log('Error loading module, retrying...', err)
                    window.location.reload()
                    return import('./main/user/user.module').then(m => m.UserModule)
                }),
        canActivate: [RegistrationGuard]
    },
    {
        path: 'people',
        component: PeopleHubComponent,
        canActivate: [RegistrationGuard]
    },
    {
        path: 'products',
        loadChildren: () =>
            import('./main/product/product.module')
                .then(m => m.ProductModule)
                .catch(err => {
                    console.log('Error loading module, retrying...', err)
                    window.location.reload()
                    return import('./main/product/product.module').then(m => m.ProductModule)
                }),
        canActivate: [RegistrationGuard]
    },
    {
        path: 'news',
        loadChildren: () =>
            import('./main/news/news.module')
                .then(m => m.NewsModule)
                .catch(err => {
                    console.log('Error loading module, retrying...', err)
                    window.location.reload()
                    return import('./main/news/news.module').then(m => m.NewsModule)
                }),
        canActivate: [RegistrationGuard]
    },
    {
        path: 'post',
        loadChildren: () =>
            import('./main/discussion/discussion.module')
                .then(m => m.DiscussionModule)
                .catch(err => {
                    console.log('Error loading module, retrying...', err)
                    window.location.reload()
                    return import('./main/discussion/discussion.module').then(m => m.DiscussionModule)
                }),
        canActivate: [RegistrationGuard]
    },
    {
        path: 'user',
        loadChildren: () =>
            import('./main/user/user.module')
                .then(m => m.UserModule)
                .catch(err => {
                    console.log('Error loading module, retrying...', err)
                    window.location.reload()
                    return import('./main/user/user.module').then(m => m.UserModule)
                }),
        canActivate: [RegistrationGuard]
    },
    {
        path: 'short/:id',
        component: NotFoundComponent
    },
    {
        path: '404',
        component: NotFoundComponent
    },
    {
        path: 'invite/:handle/about',
        component: HomeContainerComponent,
        resolve: { redirect: InviteRedirectResolver }
    },
    {
        path: ':handle',
        loadChildren: () =>
            import('./main/hive/hive.module')
                .then(m => m.HiveModule)
                .catch(err => {
                    console.log('Error loading module, retrying...', err)
                    window.location.reload()
                    return import('./main/hive/hive.module').then(m => m.HiveModule)
                }),
        canActivate: [RegistrationGuard]
    },
];

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
}

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
