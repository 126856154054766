import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Event, NavigationStart, Router } from '@angular/router';
import { Subject, Subscription, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs';
import { FilterType } from 'src/app/_enums';
import { Business, FilterDisplay, Hive, HubhiveEvent, Product, SearchFilter } from 'src/app/_models';
import { FilterAPIService } from 'src/app/_api-services';

interface SearchResult {
    data: Product | Business | Hive | HubhiveEvent
    entity: string
    identifier: string
}

interface SearchParams {
    search_term: string
    collections: {}[]
}

@Component({
  selector: 'search-everything',
  templateUrl: './search-everything.component.html',
  styleUrl: './search-everything.component.scss'
})
export class SearchEverythingComponent implements OnInit, OnDestroy {
    @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>
    
    @Output() doCloseSearch: EventEmitter<void> = new EventEmitter<void>()
    
    private subscriptions: Subscription = new Subscription()
    
   
    htmlBody: any
    searchForm!: FormGroup
    searchResults: SearchResult[] = []
    searching: boolean = false
    selectedTab: number = 0
    filterType: FilterType = FilterType.Global
    searchTerms = new Subject<string>();
    filters: FilterDisplay[] = [
        {
            name: '',
            id: '', 
            urlText: '',
            multiSelect: false,
            barFilter: false,
            filters: [
                { name: 'All', id: 'all', value: 'all', isSelected: true },
                { name: 'Hives', id: 'hives', value: 'hives', isSelected: false },
                { name: 'Businesses', id: 'businesses', value: 'businesses', isSelected: false },
                { name: 'Products', id: 'products', value: 'products', isSelected: false },
                { name: 'Events', id: 'events', value: 'events', isSelected: false },
            ]
        },
    ]
    
    filterCategories: {} = {
        "business_filter": {
            category: [],
        },
        "hive_filter": {
            category: [],
        },
        "product_filter": {
            category: [],
        },
        "event_filter": {
            category: [],
        }
    }
    
    bCategories: FilterDisplay[] = [{
        name: 'Get something done',
        id: '',
        urlText: '',
        barFilter: false,
        multiSelect: false,
        filters: [],
    }]
    hCategories: FilterDisplay[] = [{
        name: 'Discover Communities',
        id: '',
        urlText: '',
        barFilter: false,
        multiSelect: false,
        filters: [],
    }]
    pCategories: FilterDisplay[] = [{
        name: 'Go Shopping',
        id: '',
        urlText: '',
        barFilter: false,
        multiSelect: false,
        filters: [],
    }]
    eCategories: FilterDisplay[] = [{
        name: 'Hang Out',
        id: '',
        urlText: '',
        barFilter: false,
        multiSelect: false,
        filters: [],
    }]
    
    constructor(
        private filterAPIService: FilterAPIService, 
        private fb: FormBuilder,
        private router: Router,
        private el: ElementRef,
        private renderer: Renderer2,
    ) {
        this.subscriptions.add(
            this.router.events.pipe(
                filter((event): event is NavigationStart => event instanceof NavigationStart),
            ).subscribe((event: NavigationStart) => {
                this.makeBodyScrollable()
                this.closeSearch()
            })
        )
        
        this.subscriptions.add(
            this.filterAPIService.getGlobalCategories(this.filterCategories).subscribe( res => {
                for(let i = 0; i < res['business_categories'][0]['filters'].length; i++) {
                    let f = res['business_categories'][0]['filters'][i]
                    
                    this.bCategories[0].filters?.push({
                        name: f['value'],
                        id: f['value'],
                        value: f['value'],
                        isSelected: false
                    })
                }
                for(let i = 0; i < res['hive_categories'][0]['filters'].length; i++) {
                    let f = res['hive_categories'][0]['filters'][i]
                    
                    this.hCategories[0].filters?.push({
                        name: f['value'],
                        id: f['value'],
                        value: f['value'],
                        isSelected: false
                    })
                }
                for(let i = 0; i < res['product_categories'][0]['filters'].length; i++) {
                    let f = res['product_categories'][0]['filters'][i]
                    
                    this.pCategories[0].filters?.push({
                        name: f['value'],
                        id: f['value'],
                        value: f['value'],
                        isSelected: false
                    })
                }
                for(let i = 0; i < res['event_categories'][0]['filters'].length; i++) {
                    let f = res['event_categories'][0]['filters'][i]
                    
                    this.eCategories[0].filters?.push({
                        name: f['value'],
                        id: f['value'],
                        value: f['value'],
                        isSelected: false
                    })
                }
            })
        )
        
    }
    
    ngOnInit(): void {
        this.htmlBody = this.el.nativeElement.ownerDocument.body
        this.searchForm = this.fb.group({
            searchTerm: ['']
        })
        
        // Subscribe to search term changes
        this.subscriptions.add(
            this.searchTerms.pipe(
                debounceTime(300),
                switchMap(term => this.performSearch(term))
            ).subscribe()
        )
    
        // Setup form control to emit values to the search term subject
        this.subscriptions.add(
            this.searchForm.get('searchTerm')?.valueChanges.pipe(
                debounceTime(300),
                distinctUntilChanged()
            ).subscribe(term => this.searchTerms.next(term))
        )
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    getSearchParams(term: string): SearchParams {
        let params = {
            search_term: term,
            page: 1,
            per_page: 20,
            collections: [
                {
                    collection: "hives",
                    properties: "name,description",
                },
                {
                    collection: "products",
                    properties: "name,description",
                },
                {
                    collection: "businesses",
                    properties: "name,summary",
                },
                {
                    collection: "events",
                    properties: "name,description",
                },
            ]
        }
        
        let filteredCollections = []
        const selectedFilter = this.filters[0].filters!.find(filter => filter.isSelected);
        
        if (!selectedFilter || selectedFilter.id === 'all') {
            return params
        } 
        
        filteredCollections = params.collections.filter(
            collection => collection.collection === selectedFilter.id
        )
        
        params.collections = filteredCollections
        
        return params
    }
    
    handleModalFilterClicked(sf: SearchFilter): void {
        this.handleFilterClicked(sf['categoryId'], sf['filterId'])
    }
    
    handleFilterClicked(categoryId: string, filterId: string): void {
        this.filters?.forEach((category: any) => {
			category.filters?.forEach((filter: any) => {
                if (category.id === categoryId && !category.multiSelect) {
                    filter.isSelected = filter.id === filterId ? !filter.isSelected : false
                    return
                }
                
                filter.isSelected = filter.id === filterId ? !filter.isSelected : filter.isSelected
			})
		})
        
        this.manualSearch()
    }
    
    performSearch(term: string) {
        let params = this.getSearchParams(term)
        this.searching = !!this.searchInput.nativeElement.value
    
        return this.filterAPIService.getFilteredRecords(params, this.filterType, 'post').pipe(
            switchMap(results => {
                if(!results) {
                    this.searchResults = []
                    return this.searchResults
                }
                
                let formattedResults = results.map((res: SearchResult) => {
                    if (res.entity === 'product') {
                        res.data = res.data as Product
                    }
                    
                    if (res.entity === 'business') {
                        res.data = res.data as Business
                    }
                    
                    if (res.entity === 'hive') {
                        res.data = res.data as Hive
                    }
                    
                    if (res.entity === 'events') {
                        res.entity = res.entity.slice(0, -1)
                        res.data = res.data as HubhiveEvent
                    }

                    res.entity = res.entity.charAt(0).toUpperCase() + res.entity.slice(1)
                    return res
                })
        
                this.searchResults = formattedResults
    
                return formattedResults
            })
        )
    }
    
    resultIsNotProduct(item: Business | Hive | Product | HubhiveEvent): boolean {
        return item && typeof item === 'object' && 'handle' in item
    }
    
    manualSearch(): void {
        const term = this.searchForm.get('searchTerm')?.value
        this.searchTerms.next(term)
    }
    
    getURL(key: string, value: string){
		let params = new HttpParams()

        params = params.set(key, value)
        
        return params
	}
    
    searchBusinesses(f: SearchFilter): void {
        const params = this.getURL('category', f.filterId)
        this.makeBodyScrollable()
        this.router.navigate(['business'], { queryParams: {params: params}})
    }
    
    searchProducts(f: SearchFilter): void {
        const params = this.getURL('category', f.filterId)
        this.makeBodyScrollable()
        this.router.navigate(['products'], { queryParams: {params: params}})
    }
    
    searchEvents(f: SearchFilter): void {
        this.searchForm.get('searchTerm')?.patchValue(f.filterId)
    }
    
    searchHives(f: SearchFilter): void {
        this.searchForm.get('searchTerm')?.patchValue(f.filterId)
        // let value = `category=${f.filterId}`
        // this.router.navigate(['business'], { queryParams: {'params': value}})
    }
    
    makeBodyScrollable(): void {
        this.renderer.removeClass(this.htmlBody, 'no-scroll')
    }
    
    closeSearch(): void {
        this.doCloseSearch.emit()
    }
}
