import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterType } from 'src/app/_enums';
import { Business, FilterDisplay } from 'src/app/_models';

@Component({
    selector: 'business-filters',
    templateUrl: './business-filters.component.html',
    styleUrl: './business-filters.component.scss'
})
export class BusinessFiltersComponent implements OnInit {
    @Input() hiveHandle: string | null = null
    @Input() page!: number
    @Input() perPage!: number
    @Output() recordOutput: EventEmitter<Business[] | null> = new EventEmitter<Business[] | null>

    filterType: FilterType = FilterType.Business
    filters: FilterDisplay[] = []

    constructor(
    ) {

    }

    ngOnInit(): void {
        this.initializeFilters()
    }

    private initializeFilters(): void {
        this.filters = [
            {
                name: 'Sort By',
                id: 'sort_by',
                urlText: 'sort_by',
                multiSelect: false,
                barFilter: false,
                filters: [
                    { name: 'Relevance', id: 'hive_score', isSelected: false },
                    { name: 'Rating', id: 'rating', value: 'average_rating', isSelected: false },
                    { name: 'Number of reviews', id: 'number of reviews', value: 'total_reviews', isSelected: false },
                    { name: 'Distance', id: 'distance', isSelected: false },
                    { name: 'Newest', id: 'newest', isSelected: false },
                    { name: 'Most Active', id: 'most active', isSelected: false },
                    { name: 'Alphabetical', id: 'name', value: 'name', isSelected: false }
                ]
            },
            {
                name: '',
                id: 'hive_ids',
                urlText: 'hive_ids',
                multiSelect: false,
                barFilter: false,
                hidden: true,
                filters: [
                    { name: '', id: '', value: this.hiveHandle, isSelected: this.hiveHandle != null }
                ]
            },
            {
                name: 'Hive',
                id: 'hive_names',
                urlText: 'hive',
                multiSelect: true,
                barFilter: false,
                hideOn: ['hive_ids'],
                filters: []
            },
            {
                name: 'Category',
                id: 'category_names',
                urlText: 'category',
                multiSelect: true,
                barFilter: true,
                filters: []
            },
            {
                name: 'Subcategory',
                id: 'sub_category_names',
                urlText: 'subcategory',
                multiSelect: true,
                barFilter: true,
                dependsOn: 'category_names',
                filters: []
            },
            {
                name: 'Rating',
                id: 'average_rating',
                urlText: 'rating',
                multiSelect: false,
                barFilter: false,
                valueIcon: '⭐',
                filters: []
            },
            {
                name: 'Number of reviews',
                id: 'total_reviews',
                urlText: 'reviews',
                multiSelect: false,
                barFilter: false,
                valueIcon: '+',
                filters: []
            }
        ]
    }

    setBusinesses(businesses: Business[] | null) {
        this.recordOutput.emit(businesses)
    }

}
