<div *ngIf="template == 'delete-account'">
    <div class="flex align-center">
        <span (click)="goBack()" class="back"><img src="/assets/icon-back-black.svg"></span>
    </div>
    <div class="container">
        <h1>Account Deletion Request</h1>
        <ol>
            <li>
                <div class="section">
                    <p>
                        To request deletion of your account and associated data with <strong>HubHive</strong>,
                        please follow these steps:
                    </p>
                </div>
            </li>
            <li>
                <div class="section">
                    <strong>Contact Us:</strong>
                    Email <a href="mailto:support@hubhive.com">support&#64;hubhive.com</a>
                    with the subject line: <strong>"Account Deletion Request"</strong>
                </div>
            </li>
            <li>
                <div class="section">
                    <p class="section-title"><strong>Data Deleted</strong></p>
                </div>
                <div class="section">
                    <ul>
                        <li>Personal information (name, email, phone, profile)</li>
                        <li>User-generated content (posts, comments, media)</li>
                        <li>Account details
                        </li>
                    </ul>
                </div>
            </li>
            <li>
                <div class="section">
                    <p class="section-title"><strong>Data Retained</strong>
                    </p>
                    <p>Some data may be retained for legal, security, or fraud prevention purposes.

                    </p>
                </div>
            </li>
        </ol>

        <p><strong>HubHive LLC</strong></p>
    </div>

    <div *ngIf="!isLoading" class="button-container">
        <button class="btn btn-caution" (click)="deleteAccountPressed()">Deactivate Account</button>
    </div>

</div>

<confirmation-code *ngIf="template == 'confirm-delete' && !isLoading" [phoneNumber]="phoneNumber"
    [codeType]="confirmationCodeType" [isValidCode]="isValidCode"
    (onConfirmPhone)="deleteAccount($event)"></confirmation-code>

<loading-spinner *ngIf="isLoading"></loading-spinner>