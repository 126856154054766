<div class="modal">
    <div class="container">
        <div class="flex flex-center search-bar">
            <img src="/assets/icon-back-arrow.svg" alt="back arrow" (click)="closeSearch()">
            <div class="search-input-container" #searchContainer>
                <form [formGroup]="searchForm">
                    <img src="/assets/icon-search.svg" alt="search icon">
                    <input #searchInput type="text" formControlName="searchTerm" placeholder="Search for anything" autofocus>
                </form>
            </div>
        </div>

        <div *ngIf="!searching">
            <div class="quick-search">
                <div>
                    <filter-modal [filterCategories]="bCategories" [scrollFilters]="true" (selectedFilter)="searchBusinesses($event)"></filter-modal>
                </div>
            </div>
            
            <div class="quick-search">
                <div>
                    <filter-modal [filterCategories]="pCategories" [scrollFilters]="true" (selectedFilter)="searchProducts($event)"></filter-modal>
                </div>
            </div>
            
            <div class="quick-search">
                <div>
                    <filter-modal [filterCategories]="hCategories" [scrollFilters]="true" (selectedFilter)="searchHives($event)"></filter-modal>
                </div>
            </div>
            
            <div class="quick-search">
                <div>
                    <filter-modal [filterCategories]="pCategories" [scrollFilters]="true" (selectedFilter)="searchEvents($event)"></filter-modal>
                </div>
            </div>
        </div>

        <div *ngIf="searching" class="search-container">
            <filter-modal [filterCategories]="filters" [filterType]="filterType" [defaultLayout]="false" (selectedFilter)="handleModalFilterClicked($event)"></filter-modal>
            
            <div class="search-results">
                <div *ngFor="let result of searchResults" class="result">
                    <a *ngIf="result.entity == 'Hive'" [routerLink]="[result.identifier, 'businesses']">
                        <p class="suggestion-title">{{ result.data.name }}</p>
                        <p class="text-muted">{{ result.entity }}</p>
                    </a>
                    
                    <a *ngIf="result.entity == 'Business'" [routerLink]="[result.entity.toLowerCase(), result.identifier, 'details']">
                        <p class="suggestion-title">{{ result.data.name }}</p>
                        <p class="text-muted">{{ result.entity }}</p>
                    </a>
                    
                    <a *ngIf="result.entity == 'Product'" [routerLink]="[result.entity.toLowerCase() + 's', result.data.name]" [queryParams]="{'id': result.data.id}">
                        <p class="suggestion-title">{{ result.data.name }}</p>
                        <p class="text-muted">{{ result.entity }}</p>
                    </a>
                    
                    <a *ngIf="result.entity == 'Event'" [routerLink]="[result.entity.toLowerCase(), result.data.name + '/details']" [queryParams]="{'id': result.data.id}">
                        <p class="suggestion-title">{{ result.data.name }}</p>
                        <p class="text-muted">{{ result.entity }}</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>